<template>
  <div class="app-box">
    <van-form @submit="onSubmit">
    <div class="blockBox blockBox1">
      <div class="titleBox">签约城市地址</div>
      <div class="inputLi">
        <van-field
          :value="address.province + address.city"
          label="省市"
          input-align="right"
          readonly
        />
        <van-field
          :value="address.area"
          label="区/县"
          input-align="right"
          readonly
        />
      </div>
    </div>
    <!-- end -->
    <div class="blockBox">
      <div class="titleBox">入驻信息</div>
      <div class="inputLi">
        <van-field
          v-model="fortData.name"
          label="姓名"
          input-align="right"
          placeholder="姓名"
          :rules="[{ required: true}]"
        />
        <van-field
          v-model="fortData.id_card"
          label="身份证"
          input-align="right"
          placeholder="请输入身份证"
          :rules="[{validator, required: true}]"
        />
        <van-field
          v-model="fortData.phone"
          label="联系电话"
          input-align="right"
          placeholder="联系电话"
          :rules="[{ pattern, required: true}]"
        />
        <!-- end实名认证 -->
        <van-field
          value=""
          label="实名认证"
          type="tel"
          input-align="right"
          readonly
        />
      </div>
      <!-- edn -->
      <p class="textColor">身份证人像面照片</p>
      <!-- end照片上传 -->
      <div class="cardId">
        <img
          class="imgId"
          v-if="fortData.idcard_front"
          :src="fortData.idcard_front"
          alt=""
          @click="imgClick(fortData.idcard_front)"
        />
        <img class="imgId" v-else src="../../assets/img/just.jpg" alt="" />
        <van-uploader class="imgBoelf" :max-count="1" :after-read="afterRead" />
        <span class="uploadImg"
          ><i class="iconfont icon-paizhao"></i>点击上传</span
        >
      </div>
      <p class="textColor textColor1">身份证国徽面照片</p>
      <div class="cardId">
        <img
          class="imgId"
          v-if="fortData.idcard_back"
          :src="fortData.idcard_back"
          alt=""
          @click="imgClick(fortData.idcard_back)"
        />
        <img class="imgId" v-else src="../../assets/img/back.jpg" alt="" />
        <van-uploader
          class="imgBoelf"
          :max-count="1"
          :after-read="afterRead1"
        />
        <span class="uploadImg"
          ><i class="iconfont icon-paizhao"></i>点击上传</span
        >
      </div>
      <p class="bottomrWarning">
        <van-icon class="iconmIE" name="warning" />请确认后提交!
      </p>
    </div>
    <div class="blockBox">
      <div class="titleBox">合同配送地址</div>
      <div class="addressArr">
        
          <van-field
            v-model="addressArr.wholeAnalysis"
            rows="2"
            autosize
            class="poeirAll"
            type="textarea"
            placeholder="复制姓名手机地址在这，自动帮您填写"
            show-word-limit
            clearable
            @input="analysis"
          />
          <van-field
            v-model="addressArr.consignee"
            label="收货人"
            input-align="right"
            class="inputAll"
            placeholder="姓名"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="addressArr.phone"
            label="手机号"
            input-align="right"
            class="inputAll"
            placeholder="请输入手机号"
            :rules="[
              { pattern, required: true },
            ]"
          />
          <van-field
            v-model="addressArr.region"
            label="选择地区"
            input-align="right"
            class="inputAll"
            placeholder="省、市、区、街道"
            right-icon="arrow"
            readonly
            @click="selector = true"
          />
          <van-field
            v-model="addressArr.details"
            label="填写详细信息"
            input-align="right"
            class="inputAll1"
          />
          <van-field
            v-model="addressArr.remark"
            label="备注"
            input-align="right"
            class="inputAll1"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >提交</van-button
            >
          </div>
        
      </div>
      
    </div>
    </van-form>
    <!-- end -->
    <van-popup v-model="selector" round position="bottom">
      <van-area :area-list="areaList" @confirm="confirmClick" @cancel="selector = false" />
    </van-popup>

  </div>
</template>

<script>
import { getItem, setItem, removeItem } from "@/utils/storage";
import { uploadImage, sendSms,getOrdersInfo,generateContract } from "@/request/api";
import { ImagePreview, Toast } from "vant";
import AddressParse from "address-parse";
import { areaList } from '@vant/area-data';
export default {
  data() {
    return {
      pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
      selector: false,
      options: [],
      analysisArr:[],
      areaList:areaList,
      cascaderValue:'',
      address: [], //城市信息
      addressArr: {
        wholeAnalysis: "",
        consignee: "",
        phone: "",
        region: "",
        details: "",
        remark:""
      },
      fortData: {
        name: "",
        phone: "",
        idcard_front: "",
        idcard_back: "",
        id_card:""
      },
      code: "",
      isSmsSend: false,
      sendBtnText: "获取验证码",
      timer: null,
      counter: 60,
      loadNum: "60",
      show: false,
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "#EDF2F5FF",
      resultImg: "",
      isCrop: false,
    };
  },
  mounted() {
    this.address = getItem("address");
     this.getUser();
  },
  methods: {
    getUser() {
      let data = {
        id: this.$route.query.id,
      };
      getOrdersInfo(data).then((res) => {
        this.fortData.name = res.data.truename;
        this.fortData.id_card = res.data.id_card;
        this.fortData.phone = res.data.phone;
        // 身份证正
        this.fortData.idcard_front = res.data.idcard_front
        this.fortData.idcard_back = res.data.idcard_back;
      });
    },
    validator(val) {
      return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val);
    },
    confirmClick(data){
      this.analysisArr.province = data[0].code
      this.analysisArr.city = data[1].code
      this.analysisArr.area = data[2].code
      this.addressArr.region = data[0].name + data[1].name +data[2].name
      this.selector = false;
    },
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.show = false;
        })
        .catch((err) => {
          Toast.fail("签名不能为空");
        });
    },
    afterRead(file) {
       const pngIs = file.file.type === "image/jpeg";
        const jpegIs = file.file.type === "image/png";
        const isLt2M = file.file.size / 1024 / 1024 < 3;
        if (!pngIs && !jpegIs) {
          Toast("上传文件只能是 jpg、png格式!");
          return;
        }
        if (!isLt2M) {
          Toast("只能上传小于3M的图片");
          return;
        }
      Toast.loading({
        message: "上传中...",
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      let data = {
        image: file.content,
      };
      uploadImage(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          Toast.clear();
          return;
        }
        this.fortData.idcard_front = res.data;
        Toast.clear();
      });
    },
    afterRead1(file) {
       const pngIs = file.file.type === "image/jpeg";
        const jpegIs = file.file.type === "image/png";
        const isLt2M = file.file.size / 1024 / 1024 < 3;
        if (!pngIs && !jpegIs) {
          Toast("上传文件只能是 jpg、png格式!");
          return;
        }
        if (!isLt2M) {
          Toast("只能上传小于3M的图片");
          return;
        }
      Toast.loading({
        message: "上传中...",
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      let data = {
        image: file.content,
      };
      uploadImage(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          Toast.clear();
          return;
        }
        this.fortData.idcard_back = res.data;
        Toast.clear();
      });
    },
    imgClick(img) {
      ImagePreview([img]);
    },
    sendSmsCode() {
      const reg = /^1\d{10}$/;
      console.log(this.fortData.phone);
      if (!reg.test(this.fortData.phone)) {
        Toast("请输入正确的手机号码");
        return;
      }
      //   发送验证码
      let data = {
        mobile: this.fortData.phone,
      };
      sendSms(data).then((res) => {
        this.isSmsSend = true;
        this.countDown();
        Toast(res.message);
      });
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter}秒后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    // 解析地址
    analysis() {
      if (this.addressArr.wholeAnalysis) {
        const [result] = AddressParse.parse(this.addressArr.wholeAnalysis);
        let aups = '';
        // 对比省
          for(var key in this.areaList.province_list){    
            if(this.areaList.province_list[key] == result.province){
              console.log(this.areaList.province_list[key]+key)
              this.analysisArr.province = key;
            }
          }  
        //市
          for(var key in this.areaList.city_list){    
              if(this.areaList.city_list[key] == result.city){
                console.log(this.areaList.city_list[key]+key)
                this.analysisArr.city = key;
              }
            }
        //区
        for(var key in this.areaList.county_list){    
              if(this.areaList.county_list[key] == result.area){
                console.log(this.areaList.county_list[key]+key)
                this.analysisArr.area = key;
              }
            }   
        
        this.addressArr.consignee = result.name;
        this.addressArr.phone = result.mobile;
        this.addressArr.region = result.province + result.city + result.area;
        this.addressArr.details = result.details;
      }
    },
    onSubmit(values) {
      let data ={
        id:this.$route.query.id,
        truename:this.fortData.name,
        phone:this.fortData.phone,
        idcard_front:this.fortData.idcard_front,
        idcard_back:this.fortData.idcard_back,
        id_card:this.fortData.id_card,
        logistics:{
          receiver:this.addressArr.consignee,
          mobile:this.addressArr.phone,
          province_code:this.analysisArr.province,
          city_code:this.analysisArr.city,
          area_code:this.analysisArr.area,
          street:this.addressArr.details,
          remark:this.addressArr.remark
        }
      }
      generateContract(data).then(res=>{
        if(res.code != 1000){
          Toast.fail(res.message)
          return
        }
        this.$router.push({path:'/TypePage',query:{id:this.$route.query.id}});
      })
    },
    nextClick() {
      this.$router.push("/FaceRecognition");
    },

   
  },
};
</script>

<style scoped>
.blockBox {
  padding: 0.3rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 7px solid #edf2f5ff;
}
.titleBox {
  position: relative;
  padding-left: 0.2rem;
  font-size: 0.43rem;
  font-weight: bold;
}
.titleBox::before {
  content: "";
  display: inline-block;
  width: 4px;
  border-radius: 10px;
  height: 15px;
  background: #0e8affff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.inputLi ::v-deep.van-field {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f5ff;
}
.inputLi ::v-deep.van-cell::after {
  content: "";
  border: none;
}
.inputLi ::v-deep.van-field:nth-last-child(1) {
  border: none;
}
.ocndtext {
  color: #0e8affff;
  border: none;
  vertical-align: bottom;
}
.textColor {
  color: #999dadff;
  padding-bottom: 0.3rem;
  font-size: 0.2rem;
}
.cardId {
  border: 1px dashed #d6d6d6ff;
  border-radius: 10px;
  margin: 10px 0;
  text-align: center;
  padding-bottom: 20px;
  position: relative;
}
.cardId img {
  width: 80%;
  height: 80%;
  margin: auto;
  padding: 0.5rem;
}
.uploadImg {
  font-size: 0.4rem;
  color: #999dadff;
}
.icon-paizhao {
  font-size: 0.6rem;
  vertical-align: middle;
  margin-right: 5px;
}
.textColor1 {
  padding: 0;
}
.bottomrWarning {
  color: #ef1a1aff;
  font-size: 0.35rem;
}
.iconmIE {
  vertical-align: middle;
  margin-right: 3px;
  font-size: 0.4rem;
}
.blockBox1 {
  padding-bottom: 0;
}
.tonead {
  font-size: 0.4rem;
}
.blockBox2 {
  padding: 0rem 0.3rem;
}
.imgBoelf {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
.cardId .imgId {
  max-height: 4rem;
}
.maoeorf {
  margin-bottom: 0.4rem;
}
.resuImg {
  width: 100%;
  height: 5rem;
  padding: 0.5rem 0;
  border-radius: 10px;
}
.fleoUl {
  display: flex;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
}
.fleoUl .liFlex {
  flex: 1;
}
.liFlex1 {
  margin-right: 0.3rem;
}
.titleBoe {
  text-align: center;
  font-size: 0.45rem;
  background: #edf2f5ff;
  padding: 0.2rem 0;
}
.addressArr {
  padding: 0.2rem 0;
}
.addressArr ::v-deep.poeirAll {
  background: #f6f8faff;
  border-radius: 10px;
}
.addressArr ::v-deep.van-cell {
  margin-bottom: 0.3rem;
}
.addressArr ::v-deep.inputAll {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f5ff;
}
.addressArr ::v-deep.inputAll1 {
  padding: 10px 0;
}
</style>